import "./index.props.css";
import "./Accordion.props.css";
import "./Breadcrumbs.props.css";
import "./Button.props.css";
import "./Card.props.css";
import "./Colors.props.css";
import "./ContactCard.props.css";
import "./FormModule.props.css";
import "./GridMenu.props.css";
import "./Search.props.css";
import "./SkipTo.props.css";
import "./Teaser.props.css";
import "./TreeMenu.props.css";
import "./Link.props.css";
import "./CookieConsent.props.css";

import { Logo } from "./../components";

export default {
  article: {
    preambleFontSize: "clamp(1.25rem, 1.1786rem + 0.3571vw, 1.5rem)",
  },
  base: {
    background: "var(--color-light)",
    color: "var(--color-foreground)",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "1em",
    height: "100%",
    letterSpacing: "normal",
    contentLineHeightMax: "1.75",
    contentLineHeightMin: "1.25",
    contentLineHeightPrefered: "1.5",
    titleLineHeightMax: "1.5",
    titleLineHeightMin: "1",
    titleLineHeightPrefered: "1.25",
    margin: "0",
    mozOsxFontSmoothing: "grayscale",
    padding: "0",
    textRendering: "optimizeLegibility",
    webkitFontSmoothing: "antialiased",
    width: "100%",
  },
  box: {
    padding: "1.5rem",
    background: "var(--color-gray-light)",
  },
  default: {
    components: {
      Logo,
    },
  },
  expandableList: {
    contentBackground: "transparent",
    contentMargin: "1rem 0 1rem 0",
    contentPadding: "0",
    iconColor: "var(--color-action)",
    iconSize: "1.25rem",
    iconTop: "0.25rem",
    summaryColor: "var(--color-action)",
    summaryHoverTextDecoration: "underline",
    summaryTextDecoration: "underline",
    titleFontSize: "clamp(1rem, 0.9286rem + 0.3571vw, 1.25rem)",
    titleFontFamily: "var(--display-font-family)",
    titleFontWeight: "500",
    titleMarginLeft: "1.75rem",
  },
  displayFontFamily: "Open Sans, sans-serif",
  flow: "1em",
  focusBoxShadow: "0 0 0 3px var(--color-dark-blue)",
  fileslistModule: {
    itemBackground: "var(--color-green-light)",
    linkFontSize: "clamp(1rem, 0.9286rem + 0.3571vw, 1.25rem)",
    linkColor: "var(--color-action)",
    linkFontFamily: "var(--display-font-family)",
    linkFontWeight: "500",
    linkHoverColor: "var(--color-dark-blue)",
    linkHoverTextDecoration: "none",
    linkPadding: "1.5rem",
    linkTextDecoration: "underline",
    metaDataColor: "var(--color-foreground)",
    metaDataFontFamily: "var(--base-font-family)",
    metaDataFontSize: "0.875rem",
  },
  frontPageHeader: {
    rekAiBackgroundColor: "#E5EAEB",
    tabsMenuBackgroundColor: "var(--color-turquoise-light)",
  },
  gallery: {
    arrowBackgroundColor: "var(--color-beige-medium)",
    arrowDisabledBackgroundColor: "var(--color-beige-medium)",
    dotColor: "var(--color-beige-medium)",
    arrowColor: "var(--color-action)",
  },
  header: {
    background: "transparent",
  },
  image: {
    captionColor: "var(--color-meta)",
    captionFontSize: "clamp(0.875rem, 0.875rem + 0vw, 0.875rem)",
    captionGap: "0.25rem",
    creditFontSize: "clamp(0.875rem, 0.875rem + 0vw, 0.875rem)",
  },
  layout: {
    padding: "7rem",
    mobilePadding: "1rem",
  },
  list: {
    gap: "1.0625rem",
    ruleColor: "var(--color-dark-light)",
    ruleWidth: "1px",
  },
  moduleWrapper: {
    headerIconBackground: "var(--color-green)",
    headerIconColor: "var(--color-action)",
    headerLink: {
      color: "var(--color-action)",
      hoverColor: "var(--color-dark-blue)",
      fontSize: "1.125rem",
      textDecoration: "none",
      textHoverDecoration: "none",
      gap: "0.375rem",
      fontWeight: "500",
    },
    headerMarginBottom: "1rem",
    headerRuleColor: "var(--color-divider)",
    headerRuleHeight: "0.1875rem", //3px
    headerRulePosition: "0.5rem",
    titleFontSize: "clamp(1.25rem, 1.1786rem + 0.3571vw, 1.5rem)",
  },
  noticeModule: {
    contentFontSize: "0.875rem",
    titleFontSize: "clamp(1rem, 0.9286rem + 0.3571vw, 1.25rem)",
  },
  table: {
    background: "var(--color-light)",
    border: "1px solid var(--color-blue)",
    cell: {
      background: "var(--color-light)",
      border: "1px solid var(--color-blue)",
      borderColor: "var(--color-blue)",
      padding: "var(--table-padding)",
    },
    fontSize: "0.875em",
    footBorderTop: "1px solid var(--color-blue)",
    footFontWeight: "600",
    head: {
      background: "var(--color-light)",
      borderBottom: "1px solid var(--color-blue)",
      color: "var(--color-foreground)",
      fontWeight: "600",
    },
    padding: "0.75rem",
    rowDivider: "var(--color-blue)",
    textAlign: "left",
    titleFontSize: "1.5rem",
    titleFontWeight: "700",
  },
  titleFlow: "0.125em",
  textModule: {
    title: {
      fontSize: "var(--module-wrapper-title-font-size)",
    },
  },
  // Cookie Consent
  cc: {
    background: "var(--color-black)",
    foreground: "var(--color-white)",
    decline: {
      border: "2px solid white",
      background: "var(--color-black)",
      foreground: "var(--color-white)",
    },
    approve: {
      border: "2px solid transparent",
      background: "var(--color-white)",
      foregound: "var(--color-black)",
    },
    titleSize: "1.5rem",
    bodySize: "16px",
    button: {
      borderRadius: "0",
      padding: "0.5rem 1rem",
    },
    buttonGroup: {
      margin: "1rem 0 0 0",
    },
  },
  roundIcon: {
    innerSize: "1rem",
  },
  icon: {
    size: "1.25rem",
  },
};
