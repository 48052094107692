import { useLocation } from "@gatsbyjs/reach-router";
import Footer from "@whitespace/gatsby-theme-wordpress-basic/src/components/Footer";
import PageBreadcrumbs from "@whitespace/gatsby-theme-wordpress-basic/src/components/PageBreadcrumbs";
import { useIsFullWidthPage } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import AlertBanner from "../../../components/AlertBanner";
import LayoutComponent from "../../../components/LayoutComponent";

import Header from "./Header";
import * as defaultStyles from "./SiteLayout.module.css";
SiteLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SiteLayout({
  children,
  className,
  styles = defaultStyles,
}) {
  let isFullWidthPage = useIsFullWidthPage();
  const { pathname } = useLocation();
  const isSpaceNeeded =
    pathname?.startsWith("/innehall/") ||
    pathname?.startsWith("/sok") ||
    pathname?.startsWith("/en/search");

  return (
    <div className={clsx(styles.component, className)}>
      <AlertBanner />
      <Header />
      <main className={styles.main} id="main">
        {!isFullWidthPage &&
          !pathname?.startsWith("/offline-plugin-app-shell-fallback") && (
            <div className={styles.breadcrumbs}>
              <LayoutComponent type={"fullNoMargin"}>
                <PageBreadcrumbs />
              </LayoutComponent>
            </div>
          )}
        <div className={isSpaceNeeded && styles.innehall}>{children}</div>
      </main>
      <Footer />
    </div>
  );
}
