import { Section } from "@jfrk/react-heading-levels";
import { withComponentDefaults } from "@whitespace/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/Article.module.css";
import ArticleBody from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleBody";
import ArticleChildPageNav from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleChildPageNav";
import ArticleFeaturedImage from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleFeaturedImage";
import ArticleMeta from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleMeta";
import ArticlePublishDate from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticlePublishDate";
import ArticleSiblingPageNav from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleSiblingPageNav";
import ArticleTagList from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTagList";
import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import { usePageContext, useIsFullWidthPage, useIsLandingPage } from "../hooks";

import { layout } from "./../foundation";
import LandingPage from "./LandingPage";

Article.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default withComponentDefaults(Article, "article");

function Article({
  className,
  styles = defaultStyles,
  // displayMode,
  ...restProps
}) {
  let pageContext = usePageContext();

  let contentType = pageContext.contentNode?.contentType?.node?.name;
  let showPostMetadata = pageContext.displaySettings?.showPostMetadata;

  let isFullWidthPage = useIsFullWidthPage();
  let isLandingPage = useIsLandingPage();

  return (
    <article
      className={clsx(
        layout.component,
        isLandingPage
          ? layout.componentWidthFullNoSpacing
          : layout.componentWidthFull,
        className,
      )}
      {...restProps}
    >
      {!isFullWidthPage && <ArticleFeaturedImage className={styles.image} />}
      <div className={clsx(!isFullWidthPage && layout.componentPage)}>
        {!isFullWidthPage ? (
          <>
            <ArticleTitle className={styles.title} />
            <Section>
              <ArticleChildPageNav className={styles.childPages} />
              {contentType === "post" && (
                <ArticlePublishDate className={styles.publishedDate} />
              )}
              <ArticleBody />
              <footer className={styles.footer}>
                {showPostMetadata && <ArticleMeta />}
                <ArticleTagList taxonomy="tags" />
                {/* TODO: Use this component to display taxonomyterms for any post type */}
              </footer>
              <ArticleSiblingPageNav />
            </Section>
          </>
        ) : (
          <>
            {!isLandingPage && <ArticleTitle />}
            {isLandingPage ? (
              <LandingPage />
            ) : (
              <Section>
                <ArticleBody />
              </Section>
            )}
          </>
        )}
      </div>
    </article>
  );
}
